import React from "react";
import ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import {persistedStore} from "./store";
import {CssBaseline, List, ThemeProvider} from "@mui/material";
import theme from "./theme";
import Error from "./routes/error";
import Clients from "./routes/clients";
import {Root} from "./routes/root";
import RequestAccess from "./routes/requestaccess";
import {NewClient} from "./routes/new";
import {EditClient} from "./routes/edit";
import {ClerkProvider} from "@clerk/clerk-react";
import {Versions} from "./routes/versions";
import {Environments} from "./routes/environments";
import Help from "./routes/help";
import {ExistingClient} from "./routes/existing";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <Error />,
        children: [
            {

                path: "versions",
                element: <Versions/>,
            },
            {

                path: "help",
                element: <Help/>,
            },
            {

                path: "environments",
                element: <Environments/>,
            },
            {
                path: "clients",
                element: <Clients/>,
            },
            {
                path: "/",
                element: <Clients/>,
            },
            {
                path: "clients/client",
                element: <NewClient/>,
            },
            {
                path: "clients/existingclient",
                element: <ExistingClient/>,
            },
            {
                path: "clients/client/:name",
                element: <EditClient/>,
            }
        ]
    },
    {
        path: "/requestaccess",
        //loader: clusterLoader,
        element: <RequestAccess/>,
    }
]);

let persistor = persistStore(persistedStore)
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
// const mode = process.env.REACT_APP_MODE;

ReactDOM.createRoot(document.getElementById("root")).render(
    // <React.StrictMode>
    <ClerkProvider publishableKey={clerkPubKey}>
        <Provider store={persistedStore}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <RouterProvider router={router} />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </ClerkProvider>
    // </React.StrictMode>
);
