import {useRouteError} from "react-router-dom";
import {Container, Row} from "react-bootstrap";
import React from "react";

export default function Error() {
    const error = useRouteError();
    console.error(error);

    return (
        <>
            <Container className="vh-100 d-flex justify-content-center signin" fluid>
                <Row className="h-100 align-items-center">
                    <div className={"error"}>
                        <p><img className={"side-logo"} src={"/default-side-logo.png"}/></p>
                        <h1>Error</h1>
                        <p>Sorry, an unexpected error has occurred.</p>
                        <p>
                            <i>{error.statusText || error.message}</i>
                        </p>

                    </div>
                </Row>
            </Container>

        </>
    );
}