import {createSlice} from '@reduxjs/toolkit'

export const configSlice = createSlice({
        name: 'config',
        initialState: {
            token: null,
            env: "dev"
        },
    reducers: {
        setToken:(state, action) => {
            state.token = action.payload
        },
        setEnv:(state, action) => {
            state.env = action.payload
        }
    }
})

export const { setToken, setEnv } = configSlice.actions

export default configSlice.reducer