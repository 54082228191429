import React, {useCallback, useEffect, useRef, useState} from "react";
import {apiDomain, deleteClient, getClients, updateClient} from "../data";
import {useNavigate} from "react-router-dom";
import {
    Button,
    ButtonToolbar,
    Table,
    NavLink,
    Nav,
    Modal,
    Popover,
    OverlayTrigger,
    Tooltip,
    ButtonGroup, Alert
} from "react-bootstrap";
import {BiEdit, BiHide, BiLinkExternal, BiShow, BiLink, BiArrowFromBottom, BiInfoCircle} from "react-icons/bi";
import {IoMdAddCircleOutline, IoMdRemoveCircleOutline} from "react-icons/io";
import {AiOutlineDelete} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {ConfirmDeleteModal} from "../components/ConfirmDeleteModal";
import {ConfirmPromoteModal} from "../components/ConfirmPromoteModal";
import {Grid} from "react-loader-spinner";
import {useAuth, useUser} from "@clerk/clerk-react";
import {ConfirmScaleModal} from "../components/ConfirmScaleModal";



export default function Clients() {


    const navigate = useNavigate();
    const {getToken} = useAuth();
    const {user} = useUser();
    const [clients, setClients] = useState()

    const env = useSelector((state) => state.config.env)

    const [open, setOpen] = useState(false);
    const [trigger, setTrigger] = useState(false);
    const [currentName, setCurrentName] = useState(false);
    const [newSize, setNewSize] = useState(0);
    const [hasError, setHasError] = useState(false)
    const [lastUpdateTimestamp, setLastUpdateTimestap] = useState()
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [promoteModalShow, setPromoteModalShow] = useState(false);
    const [scaleModalShow, setScaleModalShow] = useState(false);

    const domain = apiDomain()
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const token = async () => {
        return await getToken({template: 'Portal'})
    }

    const _getClients = async () => {
        setClients(null)
        const data = await getClients(await token(), env);
        data.sort((a, b) => a.host.toLowerCase().localeCompare(b.host.toLowerCase()));
        setClients(data)
    }

    const _updateClient = async (name, patch) => {
        updateClient(name, patch, await token(), env).then()
    }

    const _deleteClient = async (name) => {
        deleteClient(name, await token(), env)
            .then(setCurrentName(null))
            .then(r => {
                toast.success("Deleting website. Changes will be applied shortly.")
            })
            .catch((e) => {
                toast.error("Error deleting website")
            })
    }

    useEffect(() => {
        _getClients().then()
    }, [env, trigger])

    const addClient = () => {
        navigate("/clients/client")
    }

    const addExistingClient = () => {
        navigate("/clients/existingclient")
    }

    const handleEditClient = (name) => {
        navigate(`/clients/client/${name}`)
    }

    const handleEditClientUrls = (name) => {
        navigate(`/clients/client/${name}/ingress`)
    }

    const handleScaleClient = (name, count, inc) => {
        setCurrentName(name)
        setScaleModalShow(true)
        let newSize = parseInt(count) + inc
        if (newSize < 0) newSize = 0

        setNewSize(newSize)
    }

    const reload = () => {
        setTimeout(() => {
            setTrigger(!trigger);
        }, 1000);
    }

    const handleToggleHideClient = (name, count) => {
        let patch = {
            "replicaCount": 0
        }
        if (parseInt(count) === 0) {
            patch = {
                "replicaCount": 1
            }
        }

        _updateClient(name, patch)
            .then(r => {
                toast.success("Updating website visibility. Changes will be applied shortly.")
                reload()
            })
            .catch((e) => {
                toast.error("Error updating website visibility")
            })
    }

    const handlePromoteClient = (name) => {
        setCurrentName(name)
        setPromoteModalShow(true)
    }
    const handlePromoteClientConfirmed = () => {
        // console.log("PROMOTE CLIENT", currentName, env)
        setPromoteModalShow(false)
        toast.info("This functionality is in development")
    }

    const handleDeleteClient = (name) => {
        setCurrentName(name)
        setDeleteModalShow(true)
    }
    const handleDeleteClientConfirmed = () => {
        // console.log("DELETING CLIENT", currentName, env)
        setDeleteModalShow(false)

        _deleteClient(currentName).then(navigate("/"))
    }

    const handleScaleClientConfirmed = () => {
        setScaleModalShow(false)

        let patch = {
            "replicaCount": newSize
        }

        _updateClient(currentName, patch)
            .then(r => {
                toast.success("Scaling website. Changes will be applied shortly.")
                reload()
            })
            .catch((e) => {
                toast.error("Error scaling website")
            })

    }

    // useEffect(() => {
    //     setHasError(!clients)
    // }, [clients])

    const hideIcon = (replicaCount) => {
        if (parseInt(replicaCount) === 0) {
            return <BiShow/>
        }
        return <BiHide/>
    }


    const promoteLink = (name) => {
        if (env === "prod") return <></>
        return (
            <>
                <NavLink
                    onClick={() => handlePromoteClient(name)}
                    title={`Promote ${name}`}><BiArrowFromBottom/></NavLink>
            </>
        )

    }

    const deleteLink = (name) => {
        if (env !== "prod" || (user && user.publicMetadata["fullAccess"])) {
            return <>
                <NavLink onClick={() => handleDeleteClient(name)}
                         title={`Delete ${name}`}><AiOutlineDelete color={"red"}/></NavLink>
            </>
        }
        return <>
            <NavLink disabled={true}
                     title={`Delete ${name} (Disabled)`}><AiOutlineDelete color={"grey"}/></NavLink>
        </>
    }


    const sizeInfo = (
        <Popover id="popover-positioned-bottom" title="Size">
            <Popover.Header as="h3">Size</Popover.Header>
            <Popover.Body>
                The number of instances deployed over the cluster. A minimum of 2 instances required to improve
                resilience.
            </Popover.Body>
        </Popover>
    );

    const ramInfo = (
        <Popover id="popover-positioned-bottom" title="Size">
            <Popover.Header as="h3">RAM</Popover.Header>
            <Popover.Body>
                The allocated RAM per website instance.
            </Popover.Body>
        </Popover>
    );

    const cpuInfo = (
        <Popover id="popover-positioned-bottom" title="Size">
            <Popover.Header as="h3">CPU</Popover.Header>
            <Popover.Body>
                The allocated CPU per website instance.
            </Popover.Body>
        </Popover>
    );

    const typeInfo = (
        <Popover id="popover-positioned-bottom" title="Size">
            <Popover.Header as="h3">Type</Popover.Header>
            <Popover.Body>
                The instance type. The default type of <strong>isolated</strong> uses isolated resources per website
                instance. Increasing the size will increase the resource usage.
            </Popover.Body>
        </Popover>
    );

    const versionInfo = (
        <Popover id="popover-positioned-bottom" title="Size">
            <Popover.Header as="h3">Type</Popover.Header>
            <Popover.Body>
                The application version. A value of default means this website is deployed with the default version for
                the environment (visible on the environments page).
            </Popover.Body>
        </Popover>
    );

    const cluster = () => {
        switch (env) {
            case "dev":
                return "do-tor1-vitriumone-doks-dev"
            case "uat":
                return "do-tor1-vitriumone-doks-uat"
            case "prod":
                return "do-tor1-vitriumone-doks-prod"
        }
    }

    const isDisabled = () => {
        return (user && !user.publicMetadata["fullAccess"])
    }

    const disabledColor = (defaultColour) => {
        return (isDisabled()) ? "grey" : defaultColour
    }

    const parsedVersion = (version) => {
        if (version) {
            return <strong>{version}</strong>
        }
        return <span className={"default-data"}>default</span>
    }
    const clientsList = () => {
        if (!clients) return (
            <>
                <div className={"spinner"}>
                    <Grid
                        height="32"
                        width="32"
                        color="#cccccc"
                        ariaLabel="grid-loading"
                        radius="12.5"
                        wrapperStyle={{}}
                        wrapperClass="spnner"
                        visible={true}
                    /></div>
            </>)

        return (
            <>
                <Table className={"clients-table"}>
                    <thead>
                    <tr>
                        <th>Domain Name</th>
                        <th>Screenshot</th>
                        <th>Size <OverlayTrigger placement="bottom" overlay={sizeInfo}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger></th>
                        <th>RAM <OverlayTrigger placement="bottom" overlay={ramInfo}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger></th>
                        <th>CPU <OverlayTrigger placement="bottom" overlay={cpuInfo}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger></th>
                        <th>Type <OverlayTrigger placement="bottom" overlay={typeInfo}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger></th>
                        <th>Version <OverlayTrigger placement="bottom" overlay={versionInfo}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger></th>
                        <th>Cluster</th>
                        <th>Scale</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clients.map((client) => (
                        <tr
                            key={client.name}
                        >
                            <td>
                                <a className={"client-link"} target={"_blank"}
                                   href={"https://" + client.host}>{client.host}</a><BiLinkExternal
                                style={{marginLeft: 10}}/>
                            </td>

                            <td>
                                {/*<CircleIcon sx={{fontSize: "small", color: green[500]}}/>*/}
                                <img width={"200px"} src={`${domain}/screenshot?url=${"https://" + client.host}`}/>
                            </td>
                            <td>
                                {client.replicaCount}
                            </td>
                            <td>
                                Unlimited
                            </td>
                            <td>
                                Unlimited
                            </td>
                            <td>Isolated</td>
                            <td>{parsedVersion(client.version)}</td>
                            <td>{cluster()}</td>
                            <td>
                                <Nav>
                                    <NavLink disabled={isDisabled()}
                                             onClick={() => handleScaleClient(client.name, client.replicaCount, 1)}
                                             title={`Scale ${client.name} up`}><IoMdAddCircleOutline
                                        color={disabledColor("")}/></NavLink>
                                    <NavLink disabled={isDisabled()}
                                             onClick={() => handleScaleClient(client.name, client.replicaCount, -1)}
                                             title={`Scale ${client.name} down`}><IoMdRemoveCircleOutline
                                        color={disabledColor("")}/></NavLink>
                                </Nav>

                            </td>
                            <td>
                                <Nav>
                                    <NavLink onClick={() => handleEditClient(client.name)}
                                             title={`Edit ${client.name}`}><BiEdit/></NavLink>
                                    {/*<NavLink onClick={() => handleEditClientUrls(client.name)}*/}
                                    {/*         title={`Edit ${client.name} urls`}><BiLink/></NavLink>*/}

                                    {/*<NavLink*/}
                                    {/*    onClick={() => handleToggleHideClient(client.name, client.replicaCount)}*/}
                                    {/*    title={`Toggle visibility of ${client.name}`}>{hideIcon(client.replicaCount)}</NavLink>*/}
                                    {promoteLink(client.name)}
                                    {deleteLink(client.name)}
                                </Nav>

                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </>
        )
    }


    if (!user || !user.publicMetadata["websites"]) {
        return <>
            <h1>Websites & Domains</h1>
            <Alert variant={"danger"}>
                Not permitted to view this page
            </Alert>
        </>
    }

    return (
        <>
            <h1>Websites & Domains</h1>
            <p>The following websites are currently configured for the {env} environment.</p>
            <ButtonToolbar>
                <ButtonGroup>
                    <Button onClick={addClient} variant={"secondary"} size="sm">Add website</Button>
                    <Button onClick={addExistingClient} variant={"secondary"} size="sm">Add existing website</Button>
                    <Button onClick={reload} variant={"secondary"} size="sm">Refresh list</Button>
                </ButtonGroup>
            </ButtonToolbar>

            {clientsList()}

            <ConfirmDeleteModal
                name={currentName}
                show={deleteModalShow}
                onDeleteConfirmed={handleDeleteClientConfirmed}
                onHide={() => setDeleteModalShow(false)}
            />
            <ConfirmScaleModal
                name={currentName}
                size={newSize}
                show={scaleModalShow}
                onScaleConfirmed={handleScaleClientConfirmed}
                onHide={() => setScaleModalShow(false)}
            />
            <ConfirmPromoteModal
                name={currentName}
                show={promoteModalShow}
                onPromoteConfirmed={handlePromoteClientConfirmed}
                onHide={() => setPromoteModalShow(false)}
            />
            {/*<ConfigurationAlert hasError={hasError}*/}
            {/*                    message={"No clients found. Check the configuration in the settings."}/>*/}
        </>
    )


}