import {Container, Row} from "react-bootstrap";
import React from "react";

export default function RequestAccess() {

    return (
        <Container className="vh-100 d-flex justify-content-center signin" fluid>
            <Row className="h-100 align-items-center">
                <div className={"request-access"}>
                    <p><img className={"side-logo"} src={"/default-side-logo.png"}/></p>
                    <p>Please contact the administrator for access to the portal.</p>
                </div>
            </Row>
        </Container>
    )
}