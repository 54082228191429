import {Button, Form, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";

export function ConfirmVersionEditModal({availableVersions, onEditConfirmed, onHide, data, image, operation, version, ...rest}) {

    const env = useSelector((state) => state.config.env)
    const {register, control, handleSubmit, watch, reset, formState: {errors}} = useForm({
        defaultValues: {
            version: version
        }
    });

    const label = () => {
        switch (image) {
            case "databaseMigrations":
                return "database migrations"
            default:
                return "website"
        }
    }

    const onSubmit = (d) => {
        onEditConfirmed(d.version.value)
    }


    useEffect(() => {
        reset({
            version: version
        })
    }, [version, availableVersions])

    return (

        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Update and confirm new version
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group style={{paddingRight: 10}} className="mb-3" controlId="formVersion">
                        <Form.Label>Version</Form.Label>
                        <Controller
                            name="version"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) =><Select options={availableVersions} {...field}/>}
                        />

                        {/*<div>{version}</div>*/}
                        {/*<div>{JSON.stringify(availableVersions)}</div>*/}
                    </Form.Group>
                    <p>Click OK to update the default version for the <strong>{label()}</strong> image in
                        the <strong>{env}</strong> environment from <strong>{version}</strong> to the new version specified.</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide}>Cancel</Button>
                    <Button type={"submit"} variant={"danger"}>OK</Button>
                </Modal.Footer>
            </form>
        </Modal>

    );
}