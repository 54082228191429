import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";

export function ConfirmVersionUpdateModal({ onUpdateConfirmed, onHide, data, image, operation, version, ...rest }) {

    const env = useSelector((state) => state.config.env)

    const label = () => {
        switch(image){
            case "databaseMigrations":
                return "database migrations"
            default:
                return "website"
        }
    }
    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm version update
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Click OK to update the default version for the <strong>{label()}</strong> image in the <strong>{env}</strong> environment to <strong>{version}</strong>.</p>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button variant={"danger"} onClick={onUpdateConfirmed}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}