import {
    SignedIn,
    SignedOut, SignIn,
    UserButton, useUser,
} from "@clerk/clerk-react";
import React, {useEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Col, Container, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import {setEnv} from "../slice";
import {useDispatch} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {AiOutlineCluster, AiOutlineQuestionCircle} from "react-icons/ai";
import {CgWebsite} from "react-icons/cg";
import {TbVersions} from "react-icons/tb";
import {EnvironmentBadge} from "../components/EnvironmentBadge";



export function Root() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { user } = useUser();
    const [baseUrl, setBaseUrl] = useState('');

    const noRedirect = ["/versions","/","/clients" ]

    useEffect(() => {
        const { protocol, hostname, port } = window.location;
        let url = `${protocol}//${hostname}`;

        if (protocol === 'http:' && port !== '80') {
            url += `:${port}`;
        } else if (protocol === 'https:' && port !== '443') {
            url += `:${port}`;
        }

        setBaseUrl(url)
    }, []);

    // useEffect(() => {
    //     if(user){
    //         console.log(user.publicMetadata)
    //     }
    // },[user])

    const updateEnv = (env) => {
        dispatch(setEnv(env))
        if(!noRedirect.includes(location.pathname)){
            navigate("/")
        }
    }

    const versionsLink = () => {
        if(user && user.publicMetadata["versions"]){
            return (
                <>
                    <li><Link to={"/versions"}><TbVersions
                        style={{marginRight: 8, marginTop:-3}}/>Versions</Link></li>
                </>
            )
        }
        return <></>
    }

    const environmentsLink = () => {
        if(user && user.publicMetadata["environments"]){
            return (
                <>
                    <li><Link to={"/environments"}><AiOutlineCluster
                        style={{marginRight: 8, marginTop:-3}}/>Environments</Link></li>
                </>
            )
        }
        return <></>
    }

    const websitesLink = () => {
        if(user && user.publicMetadata["websites"]){
            return (
                <>
                    <li><Link to={"/"}><CgWebsite style={{marginRight: 8, marginTop:-3}}/>Websites</Link></li>
                </>
            )
        }
        return <></>
    }

    // const adminLink = () => {
    //     if(user){ // && user.publicMetadata["admin"]){
    //         return (
    //             <>
    //                 <li><Link to={"/admin"}><CgWebsite style={{marginRight: 10}}/>Admin</Link></li>
    //             </>
    //         )
    //     }
    //     return <></>
    // }

    return (
        <>
            <SignedIn>
                <Container className="vh-100 d-flex flex-column" fluid>
                    <Row className="h-100">
                        <Col style={{width: 225}} xs={2} className={"side-nav"}>
                            <img className={"side-logo"} src={"/default-side-logo.png"}/>
                            <Nav className="side-nav">
                                <ul className="navbar-list">
                                    {websitesLink()}
                                    {versionsLink()}
                                    {environmentsLink()}
                                    {/*{adminLink()}*/}
                                    <li><Link to={"/help"}><AiOutlineQuestionCircle style={{marginRight: 8, marginTop:-3}}/>Help</Link></li>
                                </ul>
                            </Nav>
                        </Col>
                        <Col>
                            <Navbar bg="nav-bar" expand="lg">
                                <Container fluid>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="me-auto">
                                            <NavDropdown title="Environment" id="basic-nav-dropdown">
                                                <NavDropdown.Item onClick={()=> updateEnv("dev")}>Dev</NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=> updateEnv("uat")}>UAT</NavDropdown.Item>
                                                <NavDropdown.Item onClick={()=> updateEnv("prod")}>Prod</NavDropdown.Item>
                                            </NavDropdown>
                                        </Nav>
                                        <EnvironmentBadge/>
                                        <Nav>
                                            <UserButton showName={true}/>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                            <Container fluid className={"content"}>
                                <Row>
                                    <Col>
                                        <Outlet/>
                                    </Col>
                                </Row>

                            </Container>

                        </Col>

                    </Row>

                </Container>
                <ToastContainer/>
            </SignedIn>
            <SignedOut>

                <Container className="vh-100 d-flex justify-content-center signin" fluid>
                    <Row className="h-100 align-items-center">
                        <SignIn signUpUrl={`${baseUrl}/requestaccess`}/>
                    </Row>
                </Container>

            </SignedOut>
        </>
    )
}