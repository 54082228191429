import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";

export function ConfirmDeleteModal({ onDeleteConfirmed, onHide, name, ...rest }) {

    const env = useSelector((state) => state.config.env)

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm deletion
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Click OK to delete <strong>{name}</strong> from the <strong>{env}</strong> environment.</p>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button variant={"danger"} onClick={onDeleteConfirmed}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}