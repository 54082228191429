import {Alert, Button, ButtonToolbar, Card, Col, Container, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {useAuth, useUser} from "@clerk/clerk-react";
import {toast} from "react-toastify";
import {Slider} from "@mui/material";
import InputGroup from 'react-bootstrap/InputGroup';
import {ConfirmDeleteModal} from "../components/ConfirmDeleteModal";
import {ConfirmCreateModal} from "../components/ConfirmCreateModal";
import {createClient, getDNSEntries} from "../data";

export function ExistingClient() {

    const navigate = useNavigate()
    const {getToken} = useAuth();
    const env = useSelector((state) => state.config.env)
    const {register, handleSubmit, watch, formState: {errors}} = useForm();
    const {user} = useUser();
    const [managePublicDNS, setManagePublicDNS] = useState(false)
    const [manageAdditionalPublicDNS, setManageAdditionalPublicDNS] = useState(false)


    const [data, setData] = useState({})
    const [createModalShow, setCreateModalShow] = useState(false)

    const token = async () => {
        return await getToken({template: 'Portal'})
    }
    const back = () => {
        navigate("/")
    }

    const valueOrDefault = (value) => {
        if (value) {
            return value
        }
        return "Not set"
    }

    const onSubmit = (d) => {

        let rnd = (Math.random() + 1).toString(36).substring(7);
        const hostname = `${d.label.toLowerCase()}-import.vitriumone.com`

        const ingresses = []

        ingresses.push({
            host: d.publicHostname,
            managed: managePublicDNS
        })

        ingresses.push({
            host: hostname,
            managed: true
        })

        // if(d.additionalPublicHostname){
        //     ingresses.push({
        //         host: d.additionalPublicHostname,
        //         managed: manageAdditionalPublicDNS
        //     })
        // }

        const data = {
            name: d.publicHostname,
            host: d.publicHostname,
            data: {
                company: "N/A",
                firstName: "N/A",
                lastName: "N/A",
                email: "N/A",
                meta: {
                    description: "N/A",
                    keywords: "N/A",
                    title: "N/A",
                }
            },
            ingress: {
                aliases: ingresses
            },
            replicaCount: defaultSize(),
            secret: `${d.label.toLowerCase()}-${rnd}`,
            database: 'existing',
            existing: true
        }

        setData(data)
        setCreateModalShow(true)

    }

    const defaultSize = () => {
        return (env === "prod") ? 2 : 1
    }

    const domainsForm = () => {
        return <>
            <Card className={"form-card"}>
                <Card.Header>Domains</Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Public domain</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
                                        <Form.Control type="publicHostname"
                                                      placeholder="Enter existing public domain name"
                                                      defaultValue={""} {...register("publicHostname", {
                                            required: true,
                                            pattern: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i
                                        })}/>
                                        <InputGroup.Checkbox
                                            onChange={(e) => handleDNSToggle(e.target.checked, "default")}
                                            aria-label="Checkbox for following text input"/>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        The existing domain name as specified in the existing database. This will be used as the primary reference. Check this box if the DNS will be managed via Blue Flamingo’s Cloudflare
                                        account.
                                    </Form.Text>
                                    <Alert className={"form-pad-top"} variant={"danger"}>
                                        Domains managed by the customer will need to be re-configured before the website
                                        is created in order to allow SSL certificates to be generated
                                    </Alert>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                        {/*            <Form.Label>Additional public domain</Form.Label>*/}
                        {/*            <InputGroup className="mb-3">*/}
                        {/*                <InputGroup.Text id="basic-addon1">https://</InputGroup.Text>*/}
                        {/*                <Form.Control type="additionalPublicHostname" placeholder="Enter domain name"*/}
                        {/*                              defaultValue={""} {...register("additionalPublicHostname", {required: false, pattern: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i})}/>*/}
                        {/*                <InputGroup.Checkbox onChange={(e) => handleDNSToggle(e.target.checked, "additional")} aria-label="Checkbox for following text input" />*/}
                        {/*            </InputGroup>*/}
                        {/*            <Form.Text className="text-muted">*/}
                        {/*                This is an optional additional public domain. Select the checkbox if this DNS entry will be managed internally.*/}
                        {/*            </Form.Text>*/}
                        {/*        </Form.Group>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Container>
                </Card.Body>
            </Card>
        </>

    }

    const _createClient = async () => {
        createClient(data, await token(), env)
            .then(r => {
                toast.success("Creating website. Changes will be applied within 5 minutes.")
                navigate("/")
            })
            .catch((e) => {
                toast.error("Error deleting website")
            })

    }

    const handleCreateConfirmed = () => {
        setCreateModalShow(false)
        //console.log(data)
        _createClient().then(

        )

    }

    const handleDNSToggle = (checked, name) => {
        if (name === "default") {
            setManagePublicDNS(checked)
        } else {
            setManageAdditionalPublicDNS(checked)
        }

    }

    if (!user || !user.publicMetadata["websites"]) {
        return <>
            <h1>Website details</h1>
            <Alert variant={"danger"}>
                Not permitted to view this page
            </Alert>
        </>
    }

    return (
        <>
            <h1>Adding existing website</h1>
            <Alert variant={"warning"}>
                NOTE: Existing clients will not have any new data saved. <strong>Data will be sourced directly from the existing database.</strong>
            </Alert>
            <ButtonToolbar>
                <Button onClick={back} variant={"secondary"} size="sm">Back</Button>
            </ButtonToolbar>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container fluid>
                    <Row>
                        <Col>

                            <Card className={"form-card"}>
                                <Card.Header>Instance</Card.Header>
                                <Card.Body>
                                    <Container fluid>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Label</Form.Label>
                                                    <Form.Control type="label" placeholder="Enter label"
                                                                  defaultValue={""} {...register("label", {
                                                        required: true,
                                                        pattern: /^[a-z0-9-]+[a-z0-9]+$/i
                                                    })}/>
                                                    <Form.Text className="text-muted">
                                                        A short label used to identify the customer website
                                                    </Form.Text>
                                                    <Alert className={"form-pad-top"} variant={"success"}>
                                                        The label will form the default hostname for the intitial CNAME
                                                        record <strong>&lt;label&gt;.vitriumone.com</strong>. Please use
                                                        ONLY alphanumeric characters or dashes and make this label as
                                                        short as possible.
                                                    </Alert>
                                                </Form.Group>


                                            </Col>
                                        </Row>
                                        {/*<Row>*/}
                                        {/*    <Col>*/}
                                        {/*        <Form.Group style={{paddingRight:10}} className="mb-3" controlId="formVersion">*/}
                                        {/*            <Form.Label>Version</Form.Label>*/}
                                        {/*            <Form.Control type="version" placeholder="Enter version"*/}
                                        {/*                          defaultValue={""} {...register("version", {required: false, pattern: /^[0-9-]+.[0-9]+.[0-9]+$/i})}/>*/}

                                        {/*        </Form.Group>*/}

                                        {/*    </Col>*/}
                                        {/*    <Col>*/}
                                        {/*        <Form.Group style={{paddingRight:10}} className="mb-3" controlId="formSize">*/}
                                        {/*            <Form.Label>Size</Form.Label>*/}
                                        {/*            <Form.Control type="size" placeholder="Enter size (1-5)"*/}
                                        {/*                          defaultValue={defaultSize()} {...register("size", {required: true, pattern: /^[1-5]$/i})}/>*/}

                                        {/*        </Form.Group>*/}

                                        {/*    </Col>*/}
                                        {/*    <Col>*/}
                                        {/*        <Form.Group style={{paddingRight:10}} className="mb-3" controlId="formRAM">*/}
                                        {/*            <Form.Label>RAM</Form.Label>*/}
                                        {/*            <Form.Control  type="ram" placeholder="Enter RAM" {...register("ram", {required: false, pattern: /^[0-9-]+$/i})}*/}
                                        {/*                           defaultValue={""}/>*/}

                                        {/*        </Form.Group>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col>*/}
                                        {/*        <Form.Group className="mb-3" controlId="formCPU">*/}
                                        {/*            <Form.Label>CPU</Form.Label>*/}
                                        {/*            <Form.Control  type="cpu" placeholder="Enter CPU" {...register("cpu", {required: false, pattern: /^[0-9-]+$/i})}*/}
                                        {/*                           defaultValue={""}/>*/}

                                        {/*        </Form.Group>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                    </Container>
                                </Card.Body>
                            </Card>
                            {/*<Card className={"form-card"}>*/}
                            {/*    <Card.Header>Company</Card.Header>*/}
                            {/*    <Card.Body>*/}

                            {/*        <Container fluid>*/}
                            {/*            <Row>*/}
                            {/*                <Col>*/}
                            {/*                    <Form.Group className="mb-3" controlId="formCompany">*/}
                            {/*                        <Form.Label>Company name</Form.Label>*/}
                            {/*                        <Form.Control type="company" placeholder="Enter company"*/}
                            {/*                                      defaultValue={""} {...register("company", {required: true})}/>*/}
                            {/*                        <Form.Text className="text-muted">*/}
                            {/*                            The company name*/}
                            {/*                        </Form.Text>*/}
                            {/*                    </Form.Group>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*        </Container>*/}


                            {/*    </Card.Body>*/}
                            {/*</Card>*/}
                            {/*<Card className={"form-card"}>*/}
                            {/*    <Card.Header>User</Card.Header>*/}
                            {/*    <Card.Body>*/}

                            {/*        <Container fluid>*/}

                            {/*            <Row>*/}
                            {/*                <Col>*/}
                            {/*                    <Form.Group style={{paddingRight: 10}} className="mb-3"*/}
                            {/*                                controlId="formBasicEmail">*/}
                            {/*                        <Form.Label>First name</Form.Label>*/}
                            {/*                        <Form.Control type="firstName" placeholder="Enter firstname"*/}
                            {/*                                      defaultValue={""} {...register("firstName", {required: true})}/>*/}
                            {/*                    </Form.Group>*/}
                            {/*                </Col>*/}
                            {/*                <Col>*/}
                            {/*                    <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                            {/*                        <Form.Label>Last name</Form.Label>*/}
                            {/*                        <Form.Control type="lastName" placeholder="Enter lastname"*/}
                            {/*                                      defaultValue={""} {...register("lastName", {required: true})}/>*/}
                            {/*                    </Form.Group>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*            <Row>*/}
                            {/*                <Col>*/}
                            {/*                    <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                            {/*                        <Form.Label>Email</Form.Label>*/}
                            {/*                        <Form.Control type="email" placeholder="Enter email"*/}
                            {/*                                      defaultValue={""} {...register("email", {required: true})}/>*/}
                            {/*                        <Form.Text className="text-muted">*/}
                            {/*                            Administrator email*/}
                            {/*                        </Form.Text>*/}
                            {/*                    </Form.Group>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*        </Container>*/}


                            {/*    </Card.Body>*/}
                            {/*</Card>*/}
                        </Col>
                        <Col>
                            {domainsForm()}
                            {/*<Card className={"form-card"}>*/}
                            {/*    <Card.Header>Site details</Card.Header>*/}
                            {/*    <Card.Body>*/}
                            {/*        <Container fluid>*/}
                            {/*            <Row>*/}
                            {/*                <Col>*/}
                            {/*                    <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                            {/*                        <Form.Label>Site Title</Form.Label>*/}
                            {/*                        <Form.Control type="siteTitle" placeholder="Enter site title"*/}
                            {/*                                      defaultValue={""} {...register("siteTitle", {required: true})}/>*/}
                            {/*                        <Form.Text className="text-muted">*/}
                            {/*                            Site title will be displaying in the browser*/}
                            {/*                        </Form.Text>*/}
                            {/*                    </Form.Group>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*            <Row>*/}
                            {/*                <Col>*/}
                            {/*                    <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                            {/*                        <Form.Label>Site Description</Form.Label>*/}
                            {/*                        <Form.Control type="siteDescription"*/}
                            {/*                                      placeholder="Enter site description"*/}
                            {/*                                      defaultValue={""} {...register("siteDescription", {required: true})}/>*/}
                            {/*                        <Form.Text className="text-muted">*/}
                            {/*                            Site description will be indexed by search engines*/}
                            {/*                        </Form.Text>*/}
                            {/*                    </Form.Group>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*            <Row>*/}
                            {/*                <Col>*/}
                            {/*                    <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                            {/*                        <Form.Label>Site Keywords</Form.Label>*/}
                            {/*                        <Form.Control type="siteKeywords" placeholder="Enter site keywords"*/}
                            {/*                                      defaultValue={""} {...register("siteKeywords", {required: true})}/>*/}
                            {/*                        <Form.Text className="text-muted">*/}
                            {/*                            Site keywords will be indexed by search engines*/}
                            {/*                        </Form.Text>*/}
                            {/*                    </Form.Group>*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*        </Container>*/}
                            {/*    </Card.Body>*/}

                            {/*</Card>*/}

                        </Col>
                    </Row>
                    <Row>
                        <Col className={"submit-row text-center"}>
                            <Button style={{width: 100}} variant="success" type="submit">
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Container>


            </form>
            <ConfirmCreateModal
                title={"creation"}
                message={"add"}
                data={data}
                show={createModalShow}
                onCreateConfirmed={handleCreateConfirmed}
                onHide={() => setCreateModalShow(false)}
            />
        </>
    )


}