import {Alert, Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";

export function ConfirmScaleModal({ onScaleConfirmed, size, onHide, name, ...rest }) {

    const env = useSelector((state) => state.config.env)

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm resize
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Click OK to resize <strong>{name}</strong> in the <strong>{env}</strong> environment to <strong>{size}</strong>. </p>
                <Alert variant={"warning"}>
                    NOTE: A minimum of two is required for improved availability.
                </Alert>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button variant={"danger"} onClick={onScaleConfirmed}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}