import {useAuth, useUser} from "@clerk/clerk-react";
import {getImages, getVersions, updateVersions} from "../data";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Grid} from "react-loader-spinner";
import {Alert, Button, ButtonGroup, ButtonToolbar, Nav, NavLink, OverlayTrigger, Popover, Table} from "react-bootstrap";
import {IoMdAddCircleOutline} from "react-icons/io";
import {toast} from "react-toastify";
import {BiEdit, BiInfoCircle} from "react-icons/bi";
import semver from "semver";
import {ConfirmVersionUpdateModal} from "../components/ConfirmVersionUpdateModal";
import {ConfirmVersionEditModal} from "../components/ConfirmVersionEditModal";
export function Versions() {

    const env = useSelector((state) => state.config.env)
    const {getToken} = useAuth();
    const [data, setData] = useState()
    const { user } = useUser();
    const [currentVersion, setCurrentVersion] = useState();
    const [currentImage, setCurrentImage] = useState();
    const [websiteImageList, setWebsiteImageList] = useState([]);
    const [databaseImageList, setDatabaseImageList] = useState([]);
    const [availableVersions, setAvailableVersions] = useState([]);
    const [currentOperation, setCurrentOperation] = useState("inc");
    const [newVersion, setNewVersion] = useState();
    const [trigger, setTrigger] = useState(false);
    const [confirmVersionModalShow, setConfirmVersionModalShow] = useState(false);
    const [editVersionModalShow, setEditVersionModalShow] = useState(false);

    const token = async () => {
        return await getToken({template: 'Portal'})
    }

    const _getVersions = async () => {
        setData(null)
        let d = await getVersions(await token(), env)
        setData(d)

    }

    const _getImages = async (image, func) => {
        func([])
        let d = await getImages(await token(), env, image)
        // console.log(d)
        func(d)
    }

    const _updateVersions = async (patch) => {
        updateVersions(patch, await token(), env).then()
    }





    const handleVersionConfirmed = () => {

        setConfirmVersionModalShow(false)
        let website = data.website
        let databaseMigrations = data.databaseMigrations

        switch(currentImage) {
            case "website":
                if (currentOperation === "inc") {
                    website = newVersion
                }
                break;
            case "databaseMigrations":
                if (currentOperation === "inc") {
                    databaseMigrations = newVersion
                }
                break;
            default:
                break;

        }

        let patch = {
            "website": website,
            "databaseMigrations": databaseMigrations,
        }

        console.log(patch)

        // _updateVersions(patch)
        //     .then(r => {
        //         toast.success("Updating default version.")
        //         reload()
        //     })
        //     .catch((e) => {
        //         toast.error("Error updating default version.")
        //     })
    }

    const handleVersionEditConfirmed = (newVersion) => {
        setEditVersionModalShow(false)

        let website = data.website
        let databaseMigrations = data.databaseMigrations

        switch(currentImage) {
            case "website":
                website = newVersion
                break;
            case "databaseMigrations":
                databaseMigrations = newVersion
                break;
            default:
                break;
        }

        let patch = {
            "website": website,
            "databaseMigrations": databaseMigrations,
        }

        //console.log(patch)

        _updateVersions(patch)
            .then(r => {
                toast.success("Updating default version.")
                reload()
            })
            .catch((e) => {
                toast.error("Error updating default version.")
            })
    }

    const handleEditVersion = (image) => {
        setCurrentImage(image)

        switch(image) {
            case "website":
                setAvailableVersions(websiteImageList)
                setCurrentVersion(data.website)
                console.log("switching to website")
                break;
            case "databaseMigrations":
                setAvailableVersions(databaseImageList)
                setCurrentVersion(data.databaseMigrations)
                console.log("switching to database")
                break;
            default:
                break;
        }

        setCurrentOperation("edit")
        setEditVersionModalShow(true)
    }

    const handleVersionUpdate = (image, operation) => {

        let website = data.website
        let databaseMigrations = data.databaseMigrations

        switch(image) {
            case "website":
                if (operation === "inc") {
                    let nv = semver.inc(website, "patch")
                    setNewVersion(nv)
                }
                break;
            case "databaseMigrations":
                if (operation === "inc") {
                    let nv = semver.inc(databaseMigrations, "patch")
                    setNewVersion(nv)
                }
                break;
            default:
                break;
        }

        setCurrentImage(image)
        setCurrentOperation(operation)
        setConfirmVersionModalShow(true)

    }

    const versionPopover = (
        <Popover id="popover-positioned-bottom" title="Size">
            <Popover.Header as="h3">CPU</Popover.Header>
            <Popover.Body>
                The default docker image tag version. For semantic version tags, ie 1.0.0 check this matches the website footer. Dev versions are timestamped. If the website
                has a specific version applied, then specific version will override the default.
            </Popover.Body>
        </Popover>
    );

    const reload = () => {
        setTimeout(() => {
            setTrigger(!trigger);
        }, 1000);

    }

    useEffect(() => {
        _getVersions().then()
        _getImages("vitriumone-application", setWebsiteImageList).then()
        _getImages("vitriumone-database", setDatabaseImageList).then()
    }, [env, trigger])

    if (!user || !user.publicMetadata["versions"]) {
        return <>
            <h1>Versions</h1>
            <Alert variant={"danger"}>
                Not permitted to view this page
            </Alert>
        </>
    }

    if (!data) return (
        <>
            <h1>Versions</h1>
            <div className={"spinner"}>
                <Grid
                    height="32"
                    width="32"
                    color="#cccccc"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass="spnner"
                    visible={true}
                /></div>
        </>
    )

    const devTimestamp = (tag) => {
        try {
            let secs = tag.split(/[-]+/).pop() * 1000
            let date = new Date(secs)
            return date.toDateString()
        } catch {
            return ""
        }
    }
    const devVersion = () => {
        return (
            <>
                <p>The following versions are deployed in the dev environment.</p>

                <Alert variant={"warning"}>NOTE: These versions are managed
                internally and increment automatically after every developer commit to
                the <strong>develop</strong> branch.</Alert>
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button onClick={reload} variant={"secondary"} size="sm">Refresh list</Button>
                    </ButtonGroup>
                </ButtonToolbar>
                <Table className={"clients-table"}>
                    <thead>
                    <tr>
                        <th>
                            Image
                        </th>
                        <th>
                            Default version <OverlayTrigger placement="bottom" overlay={versionPopover}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger>
                        </th>
                        <th>
                            Date created
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>
                            Website
                        </th>
                        <td>
                            {data.website}
                        </td>
                        <td>
                            {devTimestamp(data.website)}
                        </td>
                        <td>
                            <em>Managed</em>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Database
                        </th>
                        <td>
                            {data.databaseMigrations}
                        </td>
                        <td>
                            {devTimestamp(data.databaseMigrations)}
                        </td>
                        <td>
                            <em>Managed</em>
                        </td>
                    </tr>
                    </tbody>
                </Table>

            </>
        )
    }

    const uatVersion = () => {
        return (
            <>
                <p>The following versions are deployed in the UAT environment. The version number is taken from the versions file in the root of the git project on the master branch.</p><Alert variant={"warning"}>NOTE: These versions are managed
                internally and increment automatically after every merge request. Check current <a
                    href={"https://gitlab.com/vitriumone/product/v1.0/website/-/merge_requests"}>merge requests</a>.</Alert>
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button onClick={reload} variant={"secondary"} size="sm">Refresh list</Button>
                    </ButtonGroup>
                </ButtonToolbar>
                <Table className={"clients-table"}>
                    <thead>
                    <tr>
                        <th>
                            Image
                        </th>
                        <th>
                            Default version <OverlayTrigger placement="bottom" overlay={versionPopover}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger>
                        </th>
                        <th>
                            Date created
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>
                            Website
                        </th>
                        <td>
                            {data.website}
                        </td>
                        <td>
                            <em>N/A</em>
                        </td>
                        <td>
                            <em>Managed</em>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Database
                        </th>
                        <td>
                            {data.databaseMigrations}
                        </td>
                        <td>
                            <em>N/A</em>
                        </td>
                        <td>
                            <em>Managed</em>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </>
        )
    }

    const prodVersion = () => {
        return (
            <>
                <p>The following versions are deployed in the prod environment. The version number is taken from the versions file in the root of the git project on the master branch.</p><Alert variant={"danger"}>NOTE: Updating the version will schedule
                a production version upgrade for any websites that do not have a fixed version specified. Please ensure the version matches a published version. UAT will typically automatically update with the latest prod release candidate version after a merge request has been accepted.</Alert>
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button onClick={reload} variant={"secondary"} size="sm">Refresh list</Button>
                    </ButtonGroup>
                </ButtonToolbar>
                <Table className={"clients-table"}>
                    <thead>
                    <tr>
                        <th>
                            Image
                        </th>
                        <th>
                            Default version <OverlayTrigger placement="bottom" overlay={versionPopover}>
                            <div style={{display: "inline"}}><BiInfoCircle/></div>
                        </OverlayTrigger>
                        </th>
                        <th>
                            Date created
                        </th>
                        <th>
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>
                            Website
                        </th>
                        <td>
                            {data.website}
                        </td>
                        <td>

                        </td>
                        <td>
                            <Nav className={"float"}>
                                <NavLink onClick={() => handleEditVersion("website")}
                                         title={`Edit`}><BiEdit/></NavLink>
                                {/*<NavLink*/}
                                {/*    onClick={() => handleVersionUpdate("website", "inc")}*/}
                                {/*    title={`Increment version`}><IoMdAddCircleOutline/></NavLink>*/}
                                {/*<NavLink*/}
                                {/*    onClick={() => handleVersionUpdate("website", "dec")}*/}
                                {/*    title={`Decrement version`}><IoMdRemoveCircleOutline/></NavLink>*/}
                            </Nav>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Database migrations
                        </th>
                        <td>
                            {data.databaseMigrations}
                        </td>
                        <td>

                        </td>
                        <td>
                            <Nav className={"float"}>
                                <NavLink onClick={() => handleEditVersion("databaseMigrations")}
                                         title={`Edit`}><BiEdit/></NavLink>
                                {/*<NavLink*/}
                                {/*    onClick={() => handleVersionUpdate("databaseMigrations", "inc")}*/}
                                {/*    title={`Increment version`}><IoMdAddCircleOutline/></NavLink>*/}
                                {/*<NavLink*/}
                                {/*    onClick={() => handleVersionUpdate("databaseMigrations", "inc")}*/}
                                {/*    title={`Decrement version`}><IoMdRemoveCircleOutline/></NavLink>*/}
                            </Nav>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </>
        )
    }

    const versionInfo = () => {
        switch (env) {
            case "prod":
                return (
                    <>
                        {prodVersion()}
                    </>
                )
            case "uat":
                return (
                    <>
                        {uatVersion()}
                    </>
                )
            default:
                return (
                    <>
                        {devVersion()}
                    </>
                )
        }
    }
    return (
        <>
            <h1>Versions</h1>
            {versionInfo()}


            <ConfirmVersionUpdateModal
                show={confirmVersionModalShow}
                onUpdateConfirmed={handleVersionConfirmed}
                image={currentImage}
                operation={currentOperation}
                version={newVersion}
                onHide={() => setConfirmVersionModalShow(false)}
            />
            <ConfirmVersionEditModal
                availableVersions={availableVersions}
                show={editVersionModalShow}
                onEditConfirmed={handleVersionEditConfirmed}
                image={currentImage}
                operation={currentOperation}
                version={currentVersion}
                onHide={() => setEditVersionModalShow(false)}
            />
        </>
    )
}