import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useEffect} from "react";

export function ConfirmPromoteModal({onPromoteConfirmed, onHide, name, ...rest}) {

    const env = useSelector((state) => state.config.env)

    const nextEnv = () => {
        return (env === "dev") ? "uat" : "prod"
    }

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm promotion
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Click OK to promote <strong>{name}</strong> from the <strong>{env}</strong> environment to the <strong>{nextEnv()}</strong> environment.</p>
                <p>This will copy the website and its configuration into the {nextEnv()} environment. Please ensure unmanaged DNS settings are correctly set</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button variant={"success"} onClick={onPromoteConfirmed}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}