import {Alert, Button, ButtonToolbar, Col, Container, Row} from "react-bootstrap";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useUser} from "@clerk/clerk-react";

export function Environments(){

    const navigate = useNavigate()
    const { user } = useUser();
    const back = () => {
        navigate("/")
    }

    if(!user || !user.publicMetadata["environments"]) {
        return <>
            <h1>Environment</h1>
            <Alert variant={"danger"}>
                Not permitted to view this page
            </Alert>
        </>
    }

    return (
        <>
            <h1>Environment</h1>
            <ButtonToolbar>
                <Button onClick={back} variant={"secondary"} size="sm">Back</Button>
            </ButtonToolbar>
            <Container fluid className={"clients-table"}>
                <Row>
                    <Col>
                        <p>This page will show environment details and will allow basic management of scaling the DigitalOcean clusters, ie adding/removing servers.</p>

                    </Col>
                </Row>
            </Container>
            </>
    )

}