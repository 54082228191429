import axios from "axios";
import {toast} from "react-toastify";




export function apiDomain(){

    // return "https://portal-doks.vitriumone.com/"

    let path = process.env.REACT_APP_PORTAL_API_URL || window.location.origin
    let browserOrigin = window.location.origin

    if(browserOrigin.includes("localhost")){
        path = "http://localhost:8000"
    } else {
        path = path.replace("portal", "api")
    }
    return path
}


export async function checkAuth(token){

    const domain = apiDomain()

    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/auth/token`
    // console.log(url)

    await axios.get(url, config)
        .then(response => {
            // console.log(response)
            toast.success("Success")
        }).catch((error) => {
            // console.log(error)
            toast.error("Auth error")
        })
}

export async function createClient(data, token, env="dev"){
    const domain = apiDomain()

    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/clients/${env}/client`
    // console.log(url)

    await axios.post(url, data, config)
        .catch((error) => {
            toast.error("Error creating client")
        })
}

export async function deleteClient(name, token, env="dev"){
    const domain = apiDomain()

    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/clients/${env}/client/delete/${name}`
    // console.log(url)

    await axios.post(url, {}, config)
        .catch((error) => {
            console.log(error)
            toast.error("Error deleting client")
        })
}

export async function updateClient(name, data, token, env="dev"){
    const domain = apiDomain()
    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/clients/${env}/client/put/${name}`
    // console.log(url)

    await axios.post(url, data, config)
}

export async function getClients(token, env="dev"){
    const domain = apiDomain()


    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/clients/${env}`
    // console.log(url)

    const clients = await axios.get(url, config).catch((error) => {
            //toast.error("Error getting clients")
    })

    if(clients){
        return clients.data.clients
    }
    return []
}

export async function getDNSEntries(domain){
    const config = {
        headers: {
            'X-Api-Key': 'abwXAA20dwKucR6XRodOVA==fhsdEMMz5E5iP8d9'
        },
    }

    let url = `https://api.api-ninjas.com/v1/dnslookup?domain=${domain}`

    const entries = await axios.get(url, config).catch((error) => {
        //toast.error("Error getting clients")
    })

    if(entries){
        return entries.data
    }
    return []
}

export async function getImages(token, env="dev", image="vitriumone-application"){
    const domain = apiDomain()


    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/images/${image}/${env}`
    // console.log(url)

    const data = await axios.get(url, config).catch((error) => {
        //toast.error("Error getting clients")
    })

    if(data){
        return data.data
    }
    return {}
}

export async function getVersions(token, env="dev"){
    const domain = apiDomain()


    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/versions/${env}`
    // console.log(url)

    const data = await axios.get(url, config).catch((error) => {
        //toast.error("Error getting clients")
    })

    if(data){
        return data.data
    }
    return {}
}

export async function updateVersions(data, token, env="dev"){
    const domain = apiDomain()
    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/versions/${env}/put`
    //console.log(url)

    await axios.post(url, data, config)
}

export async function getClient(name, token, env="dev"){
    const domain = apiDomain()
    const config = {
        withCredentials: true,
        headers: { Authorization: `Bearer ${token}` }
    };

    let url = `${domain}/api/clients/${env}/client/${name}`
    // console.log(url)

    const client = await axios.get(url, config)
        .catch((error) => {
            toast.error("Error getting client")
        })

    if(client){
        return client.data.client
    }
    return null
}