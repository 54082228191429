import {Badge} from "react-bootstrap";
import {useSelector} from "react-redux";

export function EnvironmentBadge(){
    const env = useSelector((state) => state.config.env)

    if(env === "prod"){
        return (
            <>
                <Badge className={"env-badge"} bg="primary">
                    {env}
                </Badge>
            </>
        )
    }
    return (
        <>
            <Badge className={"env-badge"} bg="secondary">
                {env}
            </Badge>
        </>
    )
}