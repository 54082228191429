import {Alert, Button, ButtonGroup, ButtonToolbar, Card, Col, Container, Form, NavLink, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getClient, getImages, updateClient} from "../data";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {useAuth, useUser} from "@clerk/clerk-react";
import {toast} from "react-toastify";
import {Grid} from "react-loader-spinner";
import InputGroup from "react-bootstrap/InputGroup";
import {BiEdit} from "react-icons/bi";
import {ConfirmCreateModal} from "../components/ConfirmCreateModal";
import Select from "react-select";

export function EditClient() {

    const navigate = useNavigate()
    const params = useParams()
    const {getToken} = useAuth();
    const [data, setData] = useState({})

    const [checked, setChecked] = useState(false);
    const env = useSelector((state) => state.config.env)
    const {user} = useUser();
    const [client, setClient] = useState()
    const {existingClient, setExistingClient} = useState()
    const [websiteImageList, setWebsiteImageList] = useState([]);
    const {loading, setLoading} = useState(true)
    const [managePublicDNS, setManagePublicDNS] = useState(false)
    const [manageAdditionalPublicDNS, setManageAdditionalPublicDNS] = useState(false)
    const [createModalShow, setCreateModalShow] = useState(false)

    const {register, handleSubmit, control, reset, watch, formState: {errors}} = useForm();

    const handleDNSToggle = (checked, name) => {
        if (name === "default") {
            setManagePublicDNS(checked)
        } else {
            setManageAdditionalPublicDNS(checked)
        }

    }

    const defaultSize = () => {
        return (env === "prod") ? 2 : 1
    }

    const token = async () => {
        return await getToken({template: 'Portal'})
    }

    const _getImages = async (image, func) => {
        func([])
        let d = await getImages(await token(), env, image)
        d.unshift({ value: "", label: "Default"})
        func(d)

    }

    const _getClient = async () => {
        const data = await getClient(params.name, await token(), env);
        setClient(data)

        console.log(data)
    }

    const isDisabled = () => {
        return (user && !user.publicMetadata["fullAccess"])
    }

    const _updateClient = async (patch) => {
        updateClient(client.name, patch, await token(), env)
    }

    useEffect(() => {
        _getClient().catch(console.error);
        _getImages("vitriumone-application", setWebsiteImageList).then()
    }, [])

    useEffect(() => {
        if (client) {
            // console.log(client.ingress.aliases)
            setManagePublicDNS(primaryAlias().managed)
            setManageAdditionalPublicDNS(additionalAlias().managed)

            // console.log(client.version)
            // console.log(websiteImageList)
        }
    }, [client])

    const back = () => {
        navigate("/")
    }

    const primaryAlias = () => {

        if (client.ingress.aliases && client.ingress.aliases[1]) {
            return client.ingress.aliases[1]
        }
        return {
            host: "",
            managed: false
        }
    }

    const additionalAlias = () => {
        if (client.ingress.aliases && client.ingress.aliases[2]) {
            return client.ingress.aliases[2]
        }
        return {
            host: "",
            managed: false
        }
    }

    const handleEditClientUrls = () => {
        navigate(`/clients/client/${client.name}/ingress`)
    }

    const handleCreateConfirmed = () => {
        setCreateModalShow(false)

        // console.log(data)

        _updateClient(data)
            .then(r => {
                toast.success("Updating website. Changes will be applied shortly.")
                navigate("/")
            })
            .catch((e) => {
                toast.error("Error updating website")
            })

    }

    const onSubmit = (d) => {


        const ingresses = []

        ingresses.push({
            host: client.name,
            managed: true
        })

        if (d.publicHostname) {
            ingresses.push({
                host: d.publicHostname,
                managed: managePublicDNS
            })
        }

        if (d.additionalPublicHostname) {
            ingresses.push({
                host: d.additionalPublicHostname,
                managed: manageAdditionalPublicDNS
            })
        }


        const patch = {
            version: d.version,
            ingress: {
                aliases: ingresses
            }
        }

        if (!isDisabled()) {
            patch["replicaCount"] = d.size
            // cpu: d.cpu,
            // ram: d.cpu,
        }

        setData(patch)
        setCreateModalShow(true)

    }

    if (!client) {
        return (
            <>
                <h1>Website details</h1>
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button onClick={back} variant={"secondary"} size="sm">Back</Button>
                        {/*<Button onClick={handleEditClientUrls} variant={"secondary"} size="sm">Edit urls</Button>*/}
                    </ButtonGroup>
                </ButtonToolbar>
                <div className={"spinner"}>
                    <Grid
                        height="32"
                        width="32"
                        color="#cccccc"
                        ariaLabel="grid-loading"
                        radius="12.5"
                        wrapperStyle={{}}
                        wrapperClass="spnner"
                        visible={true}
                    /></div>
            </>
        )
    }

    const domainsForm = () => {
        return <>
            <Card className={"form-card"}>
                <Card.Header>Domains</Card.Header>
                <Card.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Public domain</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
                                        <Form.Control type="publicHostname"
                                                      placeholder="Enter default public domain name"
                                                      defaultValue={primaryAlias().host} {...register("publicHostname", {
                                            required: false,
                                            pattern: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i
                                        })}/>
                                        <InputGroup.Checkbox checked={managePublicDNS}
                                                             onChange={(e) => handleDNSToggle(e.target.checked, "default")}
                                                             aria-label="Checkbox for following text input"/>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        Check this box if the DNS will be managed via Blue Flamingo’s Cloudflare
                                        account.
                                    </Form.Text>
                                    <Alert className={"form-pad-top"} variant={"warning"}>
                                        Domains managed by the customer will need to be preconfigured before the website
                                        is created.
                                    </Alert>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col>*/}
                        {/*        <Form.Group className="mb-3" controlId="formBasicEmail">*/}
                        {/*            <Form.Label>Additional public domain</Form.Label>*/}
                        {/*            <InputGroup className="mb-3">*/}
                        {/*                <InputGroup.Text id="basic-addon1">https://</InputGroup.Text>*/}
                        {/*                <Form.Control type="additionalPublicHostname" placeholder="Enter domain name"*/}
                        {/*                              defaultValue={""} {...register("additionalPublicHostname", {required: false, pattern: /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i})}/>*/}
                        {/*                <InputGroup.Checkbox onChange={(e) => handleDNSToggle(e.target.checked, "additional")} aria-label="Checkbox for following text input" />*/}
                        {/*            </InputGroup>*/}
                        {/*            <Form.Text className="text-muted">*/}
                        {/*                This is an optional additional public domain. Select the checkbox if this DNS entry will be managed internally.*/}
                        {/*            </Form.Text>*/}
                        {/*        </Form.Group>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                    </Container>
                </Card.Body>
            </Card>
        </>
    }

    if (!user || !user.publicMetadata["websites"]) {
        return <>
            <h1>Website details</h1>
            <Alert variant={"danger"}>
                Not permitted to view this page
            </Alert>
        </>
    }
    return (
        <>
            <h1>Website details</h1>
            <Alert variant={"warning"}>
                NOTE: The website was initially provisioned with the following details and may NOT reflect the
                current settings which are editable on the website admin console.
            </Alert>
            <p></p>
            <ButtonToolbar>
                <ButtonGroup>
                    <Button onClick={back} variant={"secondary"} size="sm">Back</Button>
                    {/*<Button onClick={handleEditClientUrls} variant={"secondary"} size="sm">Edit urls</Button>*/}

                </ButtonGroup>
            </ButtonToolbar>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Container fluid className={"clients-table"}>
                    <Row>
                        <Col>
                            <Card className={"form-card"}>
                                <Card.Header>Instance</Card.Header>
                                <Card.Body>
                                    <Container fluid>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="company" disabled placeholder="Enter name"
                                                                  defaultValue={client.name}/>

                                                </Form.Group>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={5}>
                                                <Form.Group style={{paddingRight: 10}} className="mb-3"
                                                            controlId="formVersion">
                                                    <Form.Label>Version</Form.Label>
                                                    {/*<Form.Control type="version" placeholder="Enter version"*/}
                                                    {/*              defaultValue={client.version} {...register("version", {*/}
                                                    {/*    required: false,*/}
                                                    {/*    pattern: /^[0-9-]+.[0-9]+.[0-9]+$/i*/}
                                                    {/*})}/>*/}
                                                    {/*<Controller*/}
                                                    {/*    name="version"*/}
                                                    {/*    control={control}*/}
                                                    {/*    */}
                                                    {/*    rules={{ required: false }}*/}
                                                    {/*    render={({ field }) => <Select options={websiteImageList} {...field} />}*/}
                                                    {/*/>*/}
                                                    <Form.Select defaultValue={client.version} {...register("version")}>
                                                        {websiteImageList.map((x,y) => <option key={y} value={x.value}>{x.label}</option>)}


                                                    </Form.Select>



                                                </Form.Group>

                                            </Col>
                                            <Col xs={2}>
                                                <Form.Group style={{paddingRight: 10}} className="mb-3"
                                                            controlId="formSize">
                                                    <Form.Label>Size</Form.Label>
                                                    <Form.Control type="size" placeholder="Enter size"
                                                                  disabled={isDisabled()}
                                                                  defaultValue={client.replicaCount} {...register("size", {
                                                        required: false,
                                                        pattern: /^[0-9]$/i
                                                    })}/>

                                                </Form.Group>

                                            </Col>
                                            <Col>
                                                <Form.Group style={{paddingRight: 10}} className="mb-3"
                                                            controlId="formRAM">
                                                    <Form.Label>RAM</Form.Label>
                                                    <Form.Control type="ram" disabled
                                                                  placeholder="Enter RAM" {...register("ram", {
                                                        required: false,
                                                        pattern: /^[0-9-]+$/i
                                                    })}
                                                                  defaultValue={""}/>

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formCPU">
                                                    <Form.Label>CPU</Form.Label>
                                                    <Form.Control type="cpu" disabled
                                                                  placeholder="Enter CPU" {...register("cpu", {
                                                        required: false,
                                                        pattern: /^[0-9-]+$/i
                                                    })}
                                                                  defaultValue={""}/>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Card>
                            <Card className={"form-card"}>

                                <Card.Header>Company <NavLink className={"float-end"}
                                                              href={`https://${client.name}/account-setting`}
                                                              target={"_blank"}
                                                              title={`Edit ${client.name}`}><BiEdit/></NavLink></Card.Header>
                                <Card.Body>

                                    <Container fluid>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Company name</Form.Label>
                                                    <Form.Control type="company" disabled
                                                                  placeholder="Enter firstname"
                                                                  defaultValue={client.data.company}/>
                                                    <Form.Text className="text-muted">
                                                        The company name
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>


                                </Card.Body>
                            </Card>
                            <Card className={"form-card"}>
                                <Card.Header>User <NavLink className={"float-end"}
                                                           href={`https://${client.name}/account-setting`}
                                                           target={"_blank"}
                                                           title={`Edit company`}><BiEdit/></NavLink></Card.Header>
                                <Card.Body>

                                    <Container fluid>

                                        <Row>
                                            <Col>
                                                <Form.Group style={{paddingRight: 10}} className="mb-3"
                                                            controlId="formBasicEmail">
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control type="firstName" disabled
                                                                  placeholder="Enter firstname"
                                                                  defaultValue={client.data.firstName}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Last name</Form.Label>
                                                    <Form.Control type="lastName" disabled
                                                                  placeholder="Enter lastname"
                                                                  defaultValue={client.data.lastName}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" disabled placeholder="Enter email"
                                                                  defaultValue={client.data.email}/>
                                                    <Form.Text className="text-muted">
                                                        Administrator email
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>


                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            {domainsForm()}
                            <Card className={"form-card"}>
                                <Card.Header>Site details <NavLink className={"float-end"}
                                                                   href={`https://${client.name}/account-setting-labels?lang=en`}
                                                                   target={"_blank"}
                                                                   title={`Edit site details`}><BiEdit/></NavLink></Card.Header>
                                <Card.Body>
                                    <Container fluid>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Site Title</Form.Label>
                                                    <Form.Control type="siteTitle" disabled
                                                                  placeholder="Enter site title"
                                                                  defaultValue={client.data.meta.title}/>
                                                    <Form.Text className="text-muted">
                                                        Site title will be displaying in the browser
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Site Description</Form.Label>
                                                    <Form.Control type="siteDescription" disabled
                                                                  placeholder="Enter site description"
                                                                  defaultValue={client.data.meta.description}/>
                                                    <Form.Text className="text-muted">
                                                        Site description will be indexed by search engines
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Site Keywords</Form.Label>
                                                    <Form.Control type="siteKeywords"
                                                                  placeholder="Enter site keywords" disabled
                                                                  defaultValue={client.data.meta.keywords}/>
                                                    <Form.Text className="text-muted">
                                                        Site keywords will be indexed by search engines
                                                    </Form.Text>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>

                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col className={"submit-row text-center"}>
                            <Button style={{width: 100}} variant="success" type="submit">
                                Save
                            </Button>
                        </Col>

                    </Row>
                </Container>


            </form>
            <ConfirmCreateModal
                title={"update"}
                message={"update"}
                data={data}
                show={createModalShow}
                onCreateConfirmed={handleCreateConfirmed}
                onHide={() => setCreateModalShow(false)}
            />
        </>
    )

}