import {Accordion, Alert, Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import React from "react";

export default function Help() {

    return (
        <>
            <h1>Help</h1>
            <p>The following information and links give guidance on updating and managing websites. Additional Tango links are included to provide additional step by step guides.</p>
            <Container className={"help-content"} fluid>
                <Row>
                    <Col>
                        <Card>
                            {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
                            <Card.Body>
                                <Card.Title>Adding a new website</Card.Title>
                                <Card.Text>
                                    <p>You can create a new website for the currently selected environment using the portal.</p>
                                    <Alert variant={"warning"}>The site details are used to create the initial website setup and will then be managed by the client website management settings.</Alert>

                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item><Card.Link target={"_blank"}
                                                           href={"https://app.tango.us/app/workflow/Adding-a-new-production-client-2ddf7655377b44cfa423090d114d8362"}>How
                                    to add a new production website</Card.Link></ListGroup.Item>

                            </ListGroup>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
                            <Card.Body>
                                <Card.Title>Resizing a website</Card.Title>
                                <Card.Text>
                                    <p>You can create resize a website to give it more resources that are distributed across the cluster.</p>
                                    <Alert variant={"danger"}>Resizing websites will use more RAM/CPU per instance and needs to be carefully managed and tested in small increments. Agressive resizing can result in the cluster running out of resources.</Alert>
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item><Card.Link target={"_blank"}
                                                           href={"https://app.tango.us/app/workflow/Resizing-a-website-67b809d5f67e4c21800612bf001233d1"}>How
                                    to resize a website</Card.Link></ListGroup.Item>

                            </ListGroup>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
                            <Card.Body>
                                <Card.Title>Updating dev websites</Card.Title>
                                <Card.Text>
                                    <p>Websites in the dev environment are updated automatically for every commit made
                                        to the <strong>develop</strong> branch in gitlab.</p>
                                    <p>After each commit, gitlab builds the docker image with a timestamp tag and is
                                        published to docker hub. Once published this docker image is then automatically
                                        deployed to every dev website.</p>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
                            <Card.Body>
                                <Card.Title>Updating uat websites</Card.Title>
                                <Card.Text>
                                    <p>Websites in the uat environment are updated automatically for every commit made
                                        to the <strong>master</strong> branch in gitlab.</p>

                                    <Alert variant={"warning"}>Commits to the master branch should ONLY be made via an approved merge
                                        request from the <strong>develop</strong> branch.</Alert>

                                    <Alert variant={"danger"}>Before a merge request is made ENSURE the version in the version file is correctly incremented. This will prevent a new release candidate version being built with the same version as currently deployed.</Alert>
                                    <p>After each merge, gitlab builds the docker image with a version tag taken from
                                        the value in the version file in the root of the project and is published to
                                        docker hub. Once published this docker image is then automatically deployed to
                                        every uat website.</p>
                                </Card.Text>

                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item><Card.Link target={"_blank"}
                                                           href={"https://app.tango.us/app/workflow/Deploying-to-UAT--cadf41eca9cb411bbadc4099a13c33a8"}>How
                                    to deploy to UAT</Card.Link></ListGroup.Item>

                            </ListGroup>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
                            <Card.Body>
                                <Card.Title>Updating prod websites</Card.Title>
                                <Card.Text>
                                    <p>Websites in the prod environment are updated manually from within this
                                        portal.</p>
                                    <p>Versions can be specified using the version link to modify the global default
                                        version OR via a version override on a specific website.</p>
                                    <Alert variant={"success"}>Versioning a specific website, such as a production demo site is useful in
                                        testing a rollout of the new image before updating all websites</Alert>


                                </Card.Text>


                            </Card.Body><ListGroup className="list-group-flush">
                            <ListGroup.Item><Card.Link target={"_blank"}
                                                       href={"https://app.tango.us/app/workflow/Update-default-production-version-5900933839cf439180dd32e30d7a235c"}>How
                                to update the default version</Card.Link></ListGroup.Item>
                            <ListGroup.Item><Card.Link target={"_blank"}
                                                       href={"https://app.tango.us/app/workflow/Rollback-to-a-specific-version-619c073ccea347aab93cebb72caec6a8"}>How
                                to rollback to a specific version</Card.Link></ListGroup.Item>
                            <ListGroup.Item><Card.Link target={"_blank"}
                                                       href={"https://app.tango.us/app/workflow/Specifying-a-client-specific-website-version-d80d9e4fe25649d3bbd4167a305f92da"}>How
                                to update a client version</Card.Link></ListGroup.Item>
                        </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>


        </>
    )
}