import {Alert, Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Grid} from "react-loader-spinner";
import {getDNSEntries} from "../data";

export function ConfirmCreateModal({onCreateConfirmed, onHide, title, message, data, ...rest}) {

    const env = useSelector((state) => state.config.env)
    const [publicDNSRecords, setPublicDNSRecords] = useState()
    const [publicDNSRecordsIsValid, setPublicDNSRecordsIsValid] = useState(false)
    const [ignoreDNSMessage, setIgnoreDNSMessage] = useState(false)

    useEffect(() => {
    }, [])
    const _getDNSEntries = async (hostname) => {
        getDNSEntries(hostname)
            .then(r => {
                let cname = r.filter(r => r["record_type"] === "CNAME" && r["value"].includes("vitriumone.com")).map(r => r["value"])
                setPublicDNSRecords(cname)
                setPublicDNSRecordsIsValid(cname.length > 0);
            })
            .catch((e) => {
                console.log(e)
            })

    }


    useEffect(() => {
        setPublicDNSRecords(null)
        setPublicDNSRecordsIsValid(false)
        setIgnoreDNSMessage(false)
        if (data && data.ingress && data.ingress.aliases) {
            let unmanaged = data.ingress.aliases.filter(r => r["managed"] === false).map(r => r["host"])
            if (unmanaged.length > 0) {
                //Check only the first
                let hostname = unmanaged[0]
                _getDNSEntries(hostname).then()
            } else {
                setIgnoreDNSMessage(true)
            }
        }

    }, [data])

    const dnsMessage = () => {
        if (ignoreDNSMessage) return <></>
        if (!publicDNSRecords) {
            return <><p><strong>Verifying unmanaged domains...</strong></p></>
        } else if (publicDNSRecordsIsValid) {
            return <>
                <Alert variant={"success"}>
                    Valid CNAME records have been found <strong>{publicDNSRecords.join(",")}</strong>
                </Alert>
            </>
        } else {
            return <>
                <Alert variant={"danger"}>
                    A valid CNAME record has NOT been found. Either remove the unmanaged domain or proceed with caution.
                    There may be a long delay before the domain SSL certificates are correctly generated and the new site is available.
                </Alert>
            </>
        }
    }

    return (
        <Modal
            {...rest}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirm {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {dnsMessage()}
                <p>Click OK to {message} new website into the <strong>{env}</strong> environment.</p>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cancel</Button>
                <Button variant={"danger"} onClick={onCreateConfirmed}>OK</Button>
            </Modal.Footer>
        </Modal>
    );
}